import { useLocation } from "react-router-dom";
import AboutUs from "./Components/AboutUs";
import FranzAndPer from "./Components/FranzAndPer";
import Hero from "./Components/Hero";
import TextSection from "./Components/TextSection";
import TextSectionHelpWith from "./Components/TextSectionHelpWith";
import TextSectionWhatWeDo from "./Components/TextSectionWhatWeDo";
import WhatWeDo from "./Components/WhatWeDo";
import WorkSection from "./Components/WorkSection";
import { useEffect, useRef } from "react";

const Landing = () => {
  const location = useLocation();

  const homeRef = useRef(null);

  const workRef = useRef(null);
  const aboutUsRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get("section");

    const scrollToSection = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    if (section === "work") {
      scrollToSection(workRef);
    }
    if (section === "aboutUs") {
      scrollToSection(aboutUsRef);
    }
    if (section === "contact") {
      scrollToSection(contactRef);
    }

    if (!section) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location]);

  // useEffect(() => {
  //   const updateURL = (section) => {
  //     const params = new URLSearchParams(location.search);
  //     if (section === "home") {
  //       params.delete("section");
  //     } else params.set("section", section);
  //     window.history.replaceState(null, "", `?${params.toString()}`);
  //   };

  //   const observerCallback = (entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         updateURL(entry.target.id);
  //       }
  //     });
  //   };

  //   const observerOptions = {
  //     threshold: 0.5,
  //   };

  //   const observer = new IntersectionObserver(
  //     observerCallback,
  //     observerOptions
  //   );

  //   if (homeRef.current) observer.observe(homeRef.current);
  //   if (workRef.current) observer.observe(workRef.current);
  //   if (aboutUsRef.current) observer.observe(aboutUsRef.current);
  //   if (contactRef.current) observer.observe(contactRef.current);

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [location]);

  return (
    <>
      <section id="home" ref={homeRef} />
      <Hero />
      <FranzAndPer />
      <section id="work" ref={workRef} />
      <TextSection />
      <WorkSection />

      <section id="aboutUs" ref={aboutUsRef} />
      <TextSectionWhatWeDo />
      <AboutUs />
      <TextSectionHelpWith />
      <WhatWeDo />
      <section id="contact" ref={contactRef}></section>
    </>
  );
};

export default Landing;
