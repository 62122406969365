import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import classes from "./Footer.module.css";
import { IoArrowForwardSharp } from "react-icons/io5";
import { BsStarFill } from "react-icons/bs";

const textVariants = [
  "awesome",
  "custom made",
  "great",
  "fun",
  "exciting",
  "inspiring",
  "amazing",
  "huge",
  "epic",
];

const Footer = () => {
  const [hover, setHover] = useState(false);
  const [currentVariantIndex, setCurrentVariantIndex] = useState(0);

  const emailHandler = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const callHandler = (number) => {
    window.location.href = `tel:${number}`;
  };

  const emailBothHandler = (email1, email2) => {
    window.location.href = `mailto:${email1}, ${email2}`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentVariantIndex(
        (prevIndex) => (prevIndex + 1) % textVariants.length
      );
    }, 4000); // Change text every 4 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <footer className={classes.footer}>
      <section className={classes.footerInner}>
        <span
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className={`${classes.col} ${hover ? classes.color : ""}`}
          onClick={() =>
            emailBothHandler(
              "per@stuebing-obiora.com",
              "francesco.deconto@gmail.com"
            )
          }
        >
          <span className={classes.bigText}>
            Let's create{" "}
            <IoArrowForwardSharp
              className={`${classes.icon} ${hover ? classes.marginLeft : ""} `}
            />
          </span>
          <span className={classes.bigText1}>
            something {window.innerWidth < 768 && <br />}
            <AnimatePresence mode="wait">
              <motion.span
                key={currentVariantIndex}
                initial={{ rotateX: 90, opacity: 0, y: -50 }}
                animate={{ rotateX: 0, y: 0, opacity: 1 }}
                exit={{ rotateX: -90, opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
                className={classes.rotatingText}
              >
                {textVariants[currentVariantIndex]}
              </motion.span>
            </AnimatePresence>
          </span>
        </span>
        <section className={classes.grid}>
          <section className={classes.contact}>
            <p className={`${classes.boldText}`}>Speak to us</p>
            <p
              className={`${classes.boldText} ${classes.underLine}`}
              onClick={() => callHandler("+1 (213) 556-2728")}
            >
              +1 (213) 556-2728
            </p>
            <p
              className={`${classes.boldText} ${classes.underLine}`}
              onClick={() => emailHandler("per@stuebing-obiora.com")}
            >
              per@stuebing-obiora.com
            </p>
            <p
              className={`${classes.boldText} ${classes.underLine}`}
              onClick={() => emailHandler("francesco.deconto@gmail.com")}
            >
              francesco.deconto@gmail.com
            </p>
          </section>
          <section className={classes.contact}>
            <p className={`${classes.boldText}`}>San Francisco</p>
            <p className={`${classes.muteText} `}>2341 Mission St. </p>
            <p className={`${classes.muteText} `}>San Francisco, CA 94110</p>
          </section>
          <section className={classes.contact}>
            <p className={`${classes.boldText}`}>New York</p>
            <p className={`${classes.muteText} `}>40 Clinton St. </p>
            <p className={`${classes.muteText} `}>New York City, NY 10002</p>
          </section>
          <section className={classes.contact}>
            <p className={`${classes.boldText}`}>Berlin</p>
            <p className={`${classes.muteText} `}>Ludwigkirchstr. 14 </p>
            <p className={`${classes.muteText} `}>Berlin, 10719</p>
          </section>
        </section>
        <section className={classes.copyRight}>
          <BsStarFill className={classes.star} />
          <a
            href="https://www.linkedin.com/in/per-obiora/"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            LinkedIn Per
          </a>{" "}
          <BsStarFill className={classes.star} />
          <a
            href="https://www.linkedin.com/in/francesco-de-conto/"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            LinkedIn Francesco
          </a>{" "}
        </section>
      </section>
    </footer>
  );
};

export default Footer;
