import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import classes from './WhatWeDo.module.css';

const WhatWeDo = () => {
	const offerings = [
		{
			title: 'Technology',
			stats: [
				'React.js, Redux, Vue',
				'MongoDB, Google Cloud, Redis',
				'Heroku, Firebase, AWS',
				'Express, Node, Next',
				'ML/AI with Python',
				'WooCommerce, Wordpress, Shopify',
				'& more',
			],
		},
		{
			title: 'Development',
			stats: [
				'Marketplaces',
				'eCommerce',
				'Websites & Web Apps',
				'Platform Integrations',
				'Extensions',
				'SaaS',
			],
		},
		{
			title: 'API Integrations',
			stats: [
				'Payment Gateways',
				'Shipping integrations',
				'3rd Party API’s',
				'Email Marketing',
				'Shopify, Etsy, Ebay +',
				'Analytics & Dashboards',
			],
		},
		{
			title: 'Strategy & UX',
			stats: [
				'Product Strategy',
				'User Research',
				'Wireframing & UI/UX',
				'Prototyping',
				'User Research',
				'User Flows',
			],
		},
		{
			title: 'Design',
			stats: [
				'User Interface Design',
				'Conversion Optimization',
				'Branding & Identity',
				'A/B Testing',
				'Performance Optimization',
			],
		},
		{
			title: 'Support',
			stats: [
				'Website Hosting',
				'3rd Party Integrations',
				'Performance Monitoring',
				'Website Maintenance',
				'Performance & Security',
			],
		},
	];

	const containerVariants = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: {
				staggerChildren: 0.1,
			},
		},
	};

	const itemVariants = {
		hidden: { opacity: 0, y: 50 },
		visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
	};

	const containerRef = useRef(null);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting && !isVisible) {
						setIsVisible(true);
					}
				});
			},
			{ threshold: 0.3 }
		);

		if (containerRef.current) {
			observer.observe(containerRef.current);
		}

		return () => {
			if (containerRef.current) {
				observer.unobserve(containerRef.current);
			}
		};
	}, [isVisible]);

	return (
		<motion.section
			className={classes.grid}
			variants={containerVariants}
			initial="hidden"
			animate={isVisible ? 'visible' : 'hidden'}
			ref={containerRef}
		>
			{offerings.map((offering, index) => (
				<motion.section
					key={index}
					className={classes.offering}
					variants={itemVariants}
				>
					<h3 className={classes.title}>{offering.title}</h3>
					<ul className={classes.stats}>
						{offering.stats.map((stat, index) => (
							<li key={index} className={classes.stat}>
								{stat}
							</li>
						))}
					</ul>
				</motion.section>
			))}
		</motion.section>
	);
};

export default WhatWeDo;
