import { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import classes from "./Hero.module.css";

const Hero = () => {
  const controls = useAnimation();
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const section = sectionRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  const text = "Exceptional Digital Experiences";
  const typingEffect = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5,
        staggerChildren: 0.01,
      },
    },
  };

  const letterEffect = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <section className={classes.hero} ref={sectionRef}>
      <motion.h1
        className={classes.heroText}
        variants={typingEffect}
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
      >
        {text.split("").map((char, index) => (
          <motion.span key={index} variants={letterEffect}>
            {char}
            {char === "  " && <br />}
          </motion.span>
        ))}
      </motion.h1>
    </section>
  );
};

export default Hero;
