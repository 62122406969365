import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import Footer from "./Footer/Footer.js";
import Navigation from "./NavigationBar/Navigation.js";
import classes from "./Layout.module.css";

const Layout = (props) => {
  const footerRef = useRef(null);

  const { scrollYProgress: scrollYProgressFooter } = useScroll({
    target: footerRef,
    offset: ["start end", "end start"],
  });

  const scale = useTransform(scrollYProgressFooter, [0, 1], [1, 0.85]);

  return (
    <div className={classes.pageWrapper}>
      <Navigation />

      <motion.div className={classes.contentWrapper} style={{ scale }}>
        <div className={classes.bg}>{props.children}</div>
      </motion.div>

      <span className={classes.footer} ref={footerRef}>
        <Footer />
      </span>
    </div>
  );
};

export default Layout;
