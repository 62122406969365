import Landing from "../Components/Landing/Landing";

const HomePage = () => {
  return (
    <>
      <Landing />
    </>
  );
};

export default HomePage;
