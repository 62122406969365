import { motion } from "framer-motion";
import classes from "./Navigation.module.css";
import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const menuItems = [
    { name: "Home" },
    { name: "Work", id: "work" },
    {
      name: "About us",
      id: "aboutUs",
    },
    { name: "Contact", id: "contact" },
  ];
  const [active, setActive] = useState("Home");
  const [hovered, setHovered] = useState(null);
  const [bgPosition, setBgPosition] = useState({ width: 0, left: 0 });
  const [hoverPosition, setHoverPosition] = useState({ width: 0, left: 0 });
  const menuRefs = useRef([]);

  useEffect(() => {
    const activeIndex = menuItems.findIndex((item) => item.name === active);
    const activeElement = menuRefs.current[activeIndex];
    if (activeElement) {
      setBgPosition({
        width: activeElement.offsetWidth,
        left: activeElement.offsetLeft,
      });
    }
  }, [active]);

  useEffect(() => {
    if (hovered !== null) {
      const hoverIndex = menuItems.findIndex((item) => item.name === hovered);
      const hoverElement = menuRefs.current[hoverIndex];
      if (hoverElement) {
        setHoverPosition({
          width: hoverElement.offsetWidth,
          left: hoverElement.offsetLeft,
        });
      }
    }
  }, [hovered]);

  const clickHandler = (item) => {
    const params = new URLSearchParams(location.search);
    setActive(item.name);
    if (item.id) {
      params.set("section", item.id);
    } else {
      params.delete("section");
    }

    navigate(`?${params.toString()}`);
  };

  return (
    <header className={classes.header}>
      <img
        src="https://ik.imagekit.io/kuj9zqlrx0or/Static/onezerologo.png"
        alt="Logo"
        className={classes.logo}
      />
      <nav className={classes.menu}>
        <ul className={classes.menuItems}>
          <motion.div
            className={classes.bg}
            initial={false}
            animate={{ width: bgPosition.width, left: bgPosition.left }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          />
          <motion.div
            className={classes.hoverBg}
            initial={false}
            animate={{ width: hoverPosition.width, left: hoverPosition.left }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          />
          {menuItems.map((item, index) => (
            <motion.li
              key={index}
              ref={(el) => (menuRefs.current[index] = el)}
              className={`${classes.item} ${
                item.name === active ? classes.itemActive : ""
              }`}
              onClick={() => clickHandler(item)}
              onMouseEnter={() => setHovered(item.name)}
              onMouseLeave={() => setHovered(active)}
            >
              {item.name}
            </motion.li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Navigation;
