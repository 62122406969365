import { motion, useScroll, useTransform } from "framer-motion";
import { useEffect, useRef } from "react";
import classes from "./FranzAndPer.module.css";

const FranzAndPer = () => {
  const targetRef = useRef(null);

  // Track scroll progress and position with specific offsets
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const { scrollY } = useScroll({
    target: targetRef,
    offset: ["start center", "center end"],
  });

  // Scale and borderRadius animations based on scroll progress
  const scale = useTransform(scrollYProgress, [0, 0.5, 1], [0.95, 1, 0.95]);
  const borderRadius = useTransform(scrollYProgress, [0, 0.5, 1], [30, 0, 30]);

  // Calculate the center of the scroll range
  const scrollCenter = useTransform(scrollY, [0, 1], [0.5, 0.5]);

  // Smoothly animate the background color change based on the scroll center
  const bgColor = useTransform(
    scrollYProgress,
    [
      0,
      scrollCenter.get() - 0.1,
      scrollCenter.get(),
      scrollCenter.get() + 0.1,
      1,
    ],
    ["#f3f3e9", "#f3f3e9", "#b488f1", "#b488f1", "#b488f1"]
  );

  const themeColor = useTransform(
    scrollYProgress,
    [
      0,
      scrollCenter.get() - 0.1,
      scrollCenter.get(),
      scrollCenter.get() + 0.1,
      1,
    ],
    ["#b488f1", "#b488f1", "#f3f3e9", "#f3f3e9", "#f3f3e9"]
  );

  // Update the CSS variable for background color and border radius
  useEffect(() => {
    const onChangeCallback = (latest) => {
      document.documentElement.style.setProperty("--primary-bg", latest);
    };

    const onChangeCallbackTheme = (latest) => {
      document.documentElement.style.setProperty("--theme-color", latest);
    };

    const onBorderRadiusChange = (latest) => {
      document.documentElement.style.setProperty(
        "--border-radius",
        `${latest}px`
      );
    };

    const unsubscribeBgColor = bgColor.on("change", onChangeCallback);
    const unsubscribeThemeColor = themeColor.on(
      "change",
      onChangeCallbackTheme
    );
    const unsubscribeBorderRadius = borderRadius.on(
      "change",
      onBorderRadiusChange
    );

    return () => {
      unsubscribeBgColor();
      unsubscribeThemeColor();
      unsubscribeBorderRadius();
    };
  }, [bgColor, themeColor, borderRadius]);

  const style = window.innerWidth < 768 ? borderRadius : "";

  return (
    <motion.div className={classes.showReel} style={{ scale }} ref={targetRef}>
      <div className={classes.showReelInner}>
        <h3 className={classes.text}>Founders & Developers</h3>
      </div>
      <img
        className={classes.img}
        style={{ style }}
        src="https://storage.googleapis.com/kashew_static/DSC03056-removebg.png"
        alt="Franz and Per"
      />
    </motion.div>
  );
};

export default FranzAndPer;
