import { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import classes from "./TextSectionHelpWith.module.css";

const TextSectionHelpWith = () => {
  const controls = useAnimation();
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const section = sectionRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  const text1 = "Our ";
  const highlightedText = "expertise";
  const text2 =
    " lies in creating state of the art websites and applications entirely in-house, ensuring every project meets the highest standards from start to finish.";

  const typingEffect = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5,
        staggerChildren: 0.003,
      },
    },
  };

  const letterEffect = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div className={classes.textSection} ref={sectionRef}>
      <motion.h2
        className={classes.h2Text}
        variants={typingEffect}
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
      >
        {text1.split("").map((char, index) => (
          <motion.span key={index} variants={letterEffect}>
            {char}
          </motion.span>
        ))}
        <motion.span className={classes.color} variants={letterEffect}>
          {highlightedText}
        </motion.span>
        {text2.split("").map((char, index) => (
          <motion.span
            key={index + text1.length + highlightedText.length}
            variants={letterEffect}
          >
            {char}
          </motion.span>
        ))}
      </motion.h2>
    </div>
  );
};

export default TextSectionHelpWith;
