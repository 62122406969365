import { useEffect, useRef } from "react";
import classes from "./WorkSection.module.css";
import { motion, useScroll, useTransform, easeInOut } from "framer-motion";

const WorkSection = () => {
  const targetRef = useRef(null);

  // Track scroll progress and position with specific offsets
  const { scrollYProgress, scrollY } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  // Calculate the center of the scroll range
  const scrollCenter = useTransform(scrollY, [0, 1], [0.5, 0.5]);

  // Smoothly animate the background color change based on the scroll center
  const bgColor = useTransform(
    scrollYProgress,
    [
      0,
      scrollCenter.get() - 0.1,
      scrollCenter.get(),
      scrollCenter.get() + 0.1,
      1,
    ],
    ["#b488f1", "#b488f1", "#f3f3e9", "#f3f3e9", "#f3f3e9"]
  );

  const themeColor = useTransform(
    scrollYProgress,
    [
      0,
      scrollCenter.get() - 0.1,
      scrollCenter.get(),
      scrollCenter.get() + 0.1,
      1,
    ],
    ["#f3f3e9", "#f3f3e9", "#b488f1", "#b488f1", "#b488f1"]
  );

  // Update the CSS variable for background color
  useEffect(() => {
    const onChangeCallback = (latest) => {
      document.documentElement.style.setProperty("--primary-bg", latest);
    };

    const onChangeCallbackTheme = (latest) => {
      document.documentElement.style.setProperty("--theme-color", latest);
    };

    const unsubscribe = bgColor.onChange(onChangeCallback);
    const unsubscribeTheme = themeColor.onChange(onChangeCallbackTheme);

    return () => {
      unsubscribe();
      unsubscribeTheme();
    };
  }, [bgColor, themeColor]);

  return (
    <section className={classes.col} ref={targetRef}>
      {/* First Show Reel */}
      <motion.div className={classes.showReel}>
        <div className={classes.showReelInner}>
          <h3 className={classes.text}>Kashew.com</h3>
        </div>
        <div className={classes.embed}>
          <embed src="https://www.kashew.com/" width="100%" height="100%" />
        </div>
      </motion.div>

      {/* <section className={classes.row}>
        <motion.div className={classes.showReel}>
          <div className={classes.showReelInner}>
            <h3 className={classes.text}>Newish.ai</h3>
          </div>
          <div className={classes.embedMobile}>
            <embed src="https://www.newish.ai" width="100%" height="100%" />
          </div>
        </motion.div>

        <motion.div className={classes.showReel}>
          <div className={classes.showReelInner}>
            <h3 className={classes.text}>Logistics Platform</h3>
          </div>
          <div className={classes.embedMobile}>
            <embed
              src="https://carriers.kashew.com/"
              width="100%"
              height="100%"
            />
          </div>
        </motion.div>
      </section> */}
      <motion.div className={classes.showReel}>
        <div className={classes.showReelInner}>
          <h3 className={classes.text}>Newish.ai</h3>
        </div>
        <div className={classes.embed}>
          <embed src="https://www.newish.ai" width="100%" height="100%" />
        </div>
      </motion.div>
    </section>
  );
};

export default WorkSection;
