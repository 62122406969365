import { useEffect, useRef, useState } from "react";
import { IoArrowForwardSharp } from "react-icons/io5";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import classes from "./AboutUs.module.css";

const AboutUs = () => {
  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  const valueSectionRef = useRef(null);
  const paragraphsRef = useRef([]);
  const logosRef = useRef(null);
  const [isParagraphsVisible, setIsParagraphsVisible] = useState(false);
  const [areLogosVisible, setAreLogosVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.target === valueSectionRef.current &&
            entry.isIntersecting
          ) {
            animateCount();
            observer.unobserve(entry.target);
          }
          if (
            paragraphsRef.current.includes(entry.target) &&
            entry.isIntersecting
          ) {
            setIsParagraphsVisible(true);
            observer.unobserve(entry.target);
          }
          if (entry.target === logosRef.current && entry.isIntersecting) {
            setAreLogosVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of element is visible
    );

    if (valueSectionRef.current) {
      observer.observe(valueSectionRef.current);
    }

    paragraphsRef.current.forEach((paragraph) => {
      if (paragraph) {
        observer.observe(paragraph);
      }
    });

    if (logosRef.current) {
      observer.observe(logosRef.current);
    }

    return () => {
      if (valueSectionRef.current) {
        observer.unobserve(valueSectionRef.current);
      }
      paragraphsRef.current.forEach((paragraph) => {
        if (paragraph) {
          observer.unobserve(paragraph);
        }
      });
      if (logosRef.current) {
        observer.unobserve(logosRef.current);
      }
    };
  }, []);

  const animateCount = () => {
    animate(count, 100, {
      duration: 5,
      onUpdate: (latest) => {
        count.set(latest);
      },
    });
  };

  const logos = [
    {
      name: "Kashew",
      url: "https://ik.imagekit.io/kuj9zqlrx0or/Static/companyLogosDev/kashew.png",
      link: "https://www.crunchbase.com/organization/kashew",
    },
    {
      name: "stanford",
      url: "https://ik.imagekit.io/kuj9zqlrx0or/Static/companyLogosDev/stanford.png",
    },
    {
      name: "hult prize",
      url: "https://ik.imagekit.io/kuj9zqlrx0or/Static/companyLogosDev/hp.png",
      link: "https://www.hultprize.org/winner-stories/2020/kashew/",
    },
    {
      name: "united nations",
      url: "https://ik.imagekit.io/kuj9zqlrx0or/Static/companyLogosDev/un.svg",
    },
    {
      name: "xrc",
      url: "https://ik.imagekit.io/kuj9zqlrx0or/Static/companyLogosDev/xrc.png",
    },
  ];

  const paragraphVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const logoContainerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const logoVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

  return (
    <section className={classes.main}>
      <section className={classes.textSection}>
        {[
          "We are serial founders with a proven track record in building full-stack web applications, raising venture capital, and building companies. With a Machine Learning diploma from Stanford and a Bachelor in Business administration from San Francisco we bring a unique blend of business acumen and technical expertise to every project.",
          "We have been crafting exceptional digital products for Sillicon Valley startups since 2017. In 2020 we bacame official partners to the United Nations as winners of the Hult Prize.",
        ].map((text, index) => (
          <motion.p
            key={index}
            className={classes.pText}
            variants={paragraphVariants}
            initial="hidden"
            animate={isParagraphsVisible ? "visible" : "hidden"}
            ref={(el) => (paragraphsRef.current[index] = el)}
          >
            {text}
          </motion.p>
        ))}
        <button className={classes.button}>
          Contact us <IoArrowForwardSharp className={classes.arrow} />
        </button>
      </section>
      <section className={classes.valueSection} ref={valueSectionRef}>
        <motion.div className={classes.value}>
          <section className={classes.valueNum}>
            <motion.div className={classes.number}>{rounded}</motion.div>%
          </section>
          <div className={classes.valueText}>In-house & independent</div>
        </motion.div>
        <motion.div className={classes.value}>
          <section className={classes.valueNum}>
            <motion.div className={classes.number}>{rounded}</motion.div>%
          </section>
          <div className={classes.valueText}>Custom applications</div>
        </motion.div>
      </section>

      <section className={classes.rightSection}>
        <img
          src="https://storage.googleapis.com/kashew_static/DSC03056-removebg.png"
          alt="About Us"
          className={classes.image}
        />
      </section>
      <motion.section
        className={classes.logos}
        variants={logoContainerVariants}
        initial="hidden"
        animate={areLogosVisible ? "visible" : "hidden"}
        ref={logosRef}
      >
        {logos.map((logo) => (
          <motion.img
            key={logo.name}
            src={logo.url}
            alt={logo.name}
            className={classes.logo}
            variants={logoVariants}
          />
        ))}
      </motion.section>
    </section>
  );
};

export default AboutUs;
